.card {
  border: var(--card-border);
  border-radius: var(--card-border-radius);
  box-shadow: var(--shadows-item);
  cursor: initial;
  display: inline-flex;
  flex-direction: column;
  height: var(--card-height);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  overflow: hidden;
  outline: none;
  position: relative;
  width: var(--card-width);
}

.card__clickable {
  cursor: pointer;
}

.card__clickable:focus-visible {
  outline: 2px solid var(--colors-primary-main);
  background: radial-gradient(
    transparent,
    transparent,
    var(--colors-grays-ultralight)
  );
  transition: 250ms all;
}

.card__flat {
  box-shadow: none;
}
